<template>
  <div class="flex flex-col">
    <div class="text-center py-12 text-sm leading-6" v-html="message"></div>
  </div>
</template>

<script>
export default {
  name: "remove-dialog",
  props: ["message"],
  components: {},
  methods: {},
}
</script>
